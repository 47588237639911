import React, { useState, useEffect } from "react";
import Sidebar from "../../Sidebar/Sidebar";
import Navbar from "../../Navbar/Navbar";
import img from "../../Assets/Images/Ellipse 75.png";

function EventDetails() {

    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const [feedback, setFeedback] = useState("");

    const handleRating = (value) => setRating(value);
    const handleHover = (value) => setHover(value);
    const handleFeedbackChange = (e) => setFeedback(e.target.value);
    const handleSubmit = (e) => {
        e.preventDefault();
    };

  return (
    <>
      <div className="main">
        <div className="sidebar">
            <Sidebar />
        </div>
        <div className="dev-table">
            <Navbar />
            <form className="form-great-event">
                <div className="add-event-details">
                    <h2>Client details</h2>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="form-group">
                                <label>Client Name</label>
                                <input type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label>Phone Number</label>
                                <input type="number" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label>Email Address</label>
                                <input type="email" className="form-control" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="add-event-details">
                    <h2>Recurring event</h2>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Event Name</label>
                                        <input type="text" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Start Date</label>
                                        <input type="date" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>End Date</label>
                                        <input type="date" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Start Time</label>
                                        <input type="time" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>End Time</label>
                                        <input type="time" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Location</label>
                                        <input type="text" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Discretion</label>
                                        <textarea className="form-control" rows={4}></textarea>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Media</label>
                                        <input type="file" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Sponsors</label>
                                        <input type="text" className="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Event Tag</label>
                                <select className="form-control">
                                    <option>select</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="add-event-details">
                    <h2>Organizers</h2>
                    <div className="event-organizers">
                        <div className="event-organizers-card">
                            <h3>All Access</h3>
                            <div className="organizers-card">
                                <div className="event-organizers-card-layout">
                                    <div className="event-organizers-card-layout-img">
                                        <img src={img} alt="Organizers" />
                                        <div>
                                            <h4>Adela Parkson</h4>
                                            <span>Organizing lead</span>
                                        </div>
                                    </div>
                                    <a><i className="fa-solid fa-ellipsis-vertical"></i></a>
                                </div>
                                <div className="event-organizers-card-layout">
                                    <div className="event-organizers-card-layout-img">
                                        <img src={img} alt="Organizers" />
                                        <div>
                                            <h4>Adela Parkson</h4>
                                            <span>Organizing lead</span>
                                        </div>
                                    </div>
                                    <a><i className="fa-solid fa-ellipsis-vertical"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="event-organizers-card">
                            <h3>Financial</h3>
                            <div className="organizers-card">
                                <div className="event-organizers-card-layout">
                                    <div className="event-organizers-card-layout-img">
                                        <img src={img} alt="Organizers" />
                                        <div>
                                            <h4>Adela Parkson</h4>
                                            <span>Organizing lead</span>
                                        </div>
                                    </div>
                                    <a><i className="fa-solid fa-ellipsis-vertical"></i></a>
                                </div>
                                <div className="event-organizers-card-layout">
                                    <div className="event-organizers-card-layout-img">
                                        <img src={img} alt="Organizers" />
                                        <div>
                                            <h4>Adela Parkson</h4>
                                            <span>Organizing lead</span>
                                        </div>
                                    </div>
                                    <a><i className="fa-solid fa-ellipsis-vertical"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="add-event-details">
                    <h2>Tickets</h2>
                    <div className="event-ticket">
                        <span className="Regular">Regular Ticket</span>
                        <p>Available: 1,600</p>
                    </div>
                    <div className="event-ticket">
                        <span className="Silver">Silver Ticket</span>
                        <p>Available: 1,600</p>
                    </div>
                    <div className="event-ticket">
                        <span className="Golden">Golden Ticket</span>
                        <p>Available: 1,600</p>
                    </div>
                    <div className="event-ticket total">
                        <p>Total Attendance: 1,600</p>
                    </div>
                </div>
                <div className="add-event-details">
                    <h2>Feedback</h2>
                    <div className="event-feedback">
                        <div className="feedback-stars">
                            {[...Array(5)].map((_, index) => {
                            const value = index + 1;
                            return (
                                <button
                                key={value}
                                type="button"
                                style={{
                                    background: "none",
                                    border: "none",
                                    cursor: "pointer",
                                    fontSize: "24px",
                                    color: value <= (hover || rating) ? "#FFD700" : "#E4E5E9",
                                }}
                                onClick={() => handleRating(value)}
                                onMouseEnter={() => handleHover(value)}
                                onMouseLeave={() => handleHover(0)}
                                >
                                ★
                                </button>
                            );
                            })}
                        </div>
                        
                        <div className="form-group">
                            <label>Tell Us More!    </label>
                            <textarea
                                placeholder="Write your feedback here..."
                                value={feedback}
                                className="form-control"
                                onChange={handleFeedbackChange}
                                rows="4"
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div className="div-submit">
                    <button type="submit" className="submit">Create</button>
                </div>
            </form>
        </div>
      </div>
    </>
  );
}

export default EventDetails;
