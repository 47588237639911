import React, { useState, useEffect } from "react";
import "../Assets/Css/login.css";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../AuthProvider";

function Login() {
  const [phoneOrEmail, setphoneOrEmail] = useState();
  const [password, setPassword] = useState();

  const auth = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (phoneOrEmail !== "" && password !== "") {
      auth.loginAction({
          phoneOrEmail: phoneOrEmail,
          password: password
      });
      return;
    }
  };


  return (
    <div className="login">
      <div className="row h-100">
        <div className="col-md-6">
          <div className="login-left">
            <p>Logo</p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="login-card">
            <h1>Login</h1>
            <form onSubmit={handleSubmit}>
              <div className="form-group mb-3" >
                <input
                  className="form-control"
                  id="phoneOrEmail"
                  name="phoneOrEmail"
                  type="text"
                  placeholder="Phone Or Email"
                  onChange={(e) => setphoneOrEmail(e.target.value)}
                />
              </div>

              <div className="form-group mb-3">
                <input
                  className="form-control"
                  id="Password"
                  name="Password"
                  type="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button className="submit" type="submit">
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
